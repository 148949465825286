<template>
  <div class="">
    <v-icon
      :title="$t('labels.copy_basket_list')"
      color="primary"
      class="cursor-pointer"
      @click="copyBaskets"
      >mdi-content-copy</v-icon
    >

    <div ref="copyRef" style="display: none; white-space: pre">
      <span v-html="copyItemText"></span>
    </div>
  </div>
</template>

<script>
import { copyText } from "@/libs/helpers";

export default {
  name: "PickupCopyBaskets",
  props: {
    baskets: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({
    isLoading: false,
    copyItem: {},
    copies: [],
    isWaitingDownload: false,
  }),
  computed: {
    copyItemText() {
      if (!this.baskets) {
        return null;
      }
      const jBasket = JSON.parse(this.baskets);
      if (!jBasket || jBasket.length === 0) {
        return null;
      }

      let html = "";
      for (let j = 0; j < jBasket.length; j++) {
        const jb = jBasket[j];
        html += `⊛ ${jb.index} - ${jb.code}\n`;
      }
      return html;
    },
  },
  methods: {
    copyText,
    copyBaskets() {
      this.$vToastify.success(this.$t("messages.copy_success"));
      this.copyText(this.$refs.copyRef.textContent);
    },
  },
};
</script>

<style scoped></style>
